import { useState } from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import PageHeader from "../PageHeader";
import HomepageContent from "./HomepageContent";
import "./styles.scss";
import DarkModeContext from "../../contexes/DarkModeContext";
import { PROD_STAGE } from "../../constants";
import { StageContext } from "../../contexes/OrderIdContext";

export default function Homepage() {
  const [darkMode, setDarkMode] = useState(false);
  const [stage, setStage] = useState(PROD_STAGE);

  return (
    <DarkModeContext.Provider value={darkMode}>
      <StageContext.Provider value={stage}>
        <AppLayout
          content={<HomepageContent setStage={setStage} />}
          contentType="default"
          contentHeader={<PageHeader setDarkMode={setDarkMode} />}
          navigationHide
          toolsHide
        />
      </StageContext.Provider>
    </DarkModeContext.Provider>
  );
}
