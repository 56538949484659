import { Cbe, Event } from "../lusca-search-api/generated-src";

export default function removeEmpty(obj: Event | Cbe) {
  return Object.fromEntries(
    Object.entries(obj)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, v]) => v != null)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
}
