// Promise polyfill
import "core-js/features/promise";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import App from "./components/App";
import "@amzn/awsui-global-styles/polaris.css";
import { getMidwayJwtToken } from "./auth/MidwayJwtToken";
import { IAppSettings, initializeAppSettings } from "./config/AppSettings";

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-param-reassign
  config.headers.Authorization = `Bearer ${jwtToken}`;
  return config;
});

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios("/settings.json")).data as IAppSettings;
  initializeAppSettings(appSettings);

  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.querySelector("#app")
  );
})().catch((error) => console.log(error));
