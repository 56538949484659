import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import SearchHistory from "./SearchHistory";
import { StageContext } from "../contexes/OrderIdContext";
import { useContext } from "react";

export default function LandingPage() {
  const stage = useContext(StageContext);

  return (
    <SpaceBetween size="l">
      <Container>
        <Box variant="h2" padding={{ top: "n" }}>
          Lusca - Prime Video&apos;s Accounting Engine
        </Box>
        <Box variant="p">
          Lusca is a modern accounting engine built by Prime Video FinSys. It
          converts Prime Video transactions into Canonical Business Events, and
          books them into Amazon&apos;s ledger.
        </Box>
      </Container>
      <Container>
        <Box variant="h2" padding={{ top: "xs" }}>
          Wiki Links
        </Box>
        <ColumnLayout columns={2} variant="text-grid">
          <Header>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href="https://w.amazon.com/bin/view/AmazonVideo/TRACK"
            >
              TRACK (Finsys Variable Accounting)
            </Link>
          </Header>
          <Header>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href="https://w.amazon.com/bin/view/AmazonVideo/TRACK/Systems/Lusca/"
            >
              Lusca - Prime Video&apos;s Accounting Engine
            </Link>
          </Header>
        </ColumnLayout>
      </Container>
      <StageContext.Provider value={stage}>
        <SearchHistory />
      </StageContext.Provider>
    </SpaceBetween>
  );
}
