import { Box, Header, Table, Button } from "@amzn/awsui-components-react";
import { useSearchParams } from "react-router-dom";
import { SearchHistory } from "../types";

export default function SearchHistory() {
  const orderIds: SearchHistory[] = JSON.parse(
    localStorage.getItem("orderIds") || "[]"
  ) as SearchHistory[];

  const [, setSearchParams] = useSearchParams();

  const handleLinkClick = (orderId: string, stage: string) => {
    setSearchParams({ stage: stage, orderId: orderId }, { replace: false });
  };

  return (
    <Table
      columnDefinitions={[
        {
          id: "index",
          header: "Index",
          cell: (item) => item.name || "-",
        },
        {
          id: "orderId",
          header: "Order Id",
          cell: (item) => item.alt || "-",
        },
        {
          id: "stage",
          header: "Stage",
          cell: (item) => item.stage || "-",
        },
        {
          id: "date",
          header: "Date",
          cell: (item) => item.description || "-",
        },
      ]}
      items={orderIds.slice(0, 10).map((obj, index) => {
        return {
          name: index + 1,
          alt: (
            <Button
              variant="link"
              onClick={() => handleLinkClick(obj.orderId, obj.stage)}
              iconAlign="right"
            >
              {obj.orderId}
            </Button>
          ),
          stage: obj.stage,
          description: obj.date,
          type: "1A",
          size: "Small",
        };
      })}
      loadingText="Loading resources"
      sortingDisabled
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
      header={<Header> Last Searches </Header>}
    />
  );
}
