/* eslint-disable no-nested-ternary */
import { Box, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { useContext } from "react";
import ErrorAlert from "./ErrorAlert";
import LandingPage from "./LandingPage";
import OrderIdBar from "./OrderIdBar/OrderIdBar";
import Results from "./Results";
import Summary from "./Summary";
import useFetchEvent from "../hooks/useFetchEvent";
import useFetchCbe from "../hooks/useFetchCbe";
import { CanonicalBusinessEvent, AccountingEventsGroup } from "../types";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";

export default function MainDataPage() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);

  const {
    data: eventData,
    error: eventError,
    isFetching: eventFetching,
    isLoading: eventLoading,
  } = useFetchEvent(stage, orderId);

  const {
    data: cbeData,
    error: cbeError,
    isFetching: cbeFetching,
    isLoading: cbeLoading,
  } = useFetchCbe(stage, orderId);

  const isEventAndCbeEmpty =
    eventData !== undefined &&
    eventData.length === 0 &&
    cbeData !== undefined &&
    cbeData.length === 0;

  const isLoadingData = cbeLoading || eventLoading;

  const isFetchingData = cbeFetching || eventFetching;

  const isError = cbeError !== null && eventError !== null;

  return (
    <div>
      {isLoadingData && !isFetchingData ? (
        <LandingPage />
      ) : isFetchingData ? (
        <Box textAlign="center">
          <Spinner size="big" />
        </Box>
      ) : isError ? (
        <ErrorAlert
          header="An error occured while fetching the data"
          content={`Could not fetch the data from server. You might not have permisson to use this tool. (${
            cbeError as string
          })`}
        />
      ) : isEventAndCbeEmpty ? (
        <ErrorAlert
          header="Could not find any events or CBEs with given order id"
          content="Order with the given order id might not exist."
        />
      ) : (
        <SpaceBetween size="xl">
          <OrderIdBar />
          <Summary
            eventData={eventData as AccountingEventsGroup[]}
            cbeData={cbeData as CanonicalBusinessEvent[]}
          />
          <Results />
        </SpaceBetween>
      )}
    </div>
  );
}
