import { useQuery } from "@tanstack/react-query";
import { EventsApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import { AccountingEventsGroup } from "../types";
import removeEmpty from "../helpers/removeEmpty";

export default function useFetchEvent(stage: string, postId: string) {
  const EventsApi = EventsApiFactory();

  const getEvent = async () => {
    const { data } = await EventsApi.getEvents(stage, postId);
    const eventData = data.map((element) => {
      return removeEmpty(element) as AccountingEventsGroup;
    });
    return eventData;
  };

  return useQuery(["post event", postId], () => getEvent(), {
    refetchOnWindowFocus: false,
    enabled: false, // disable this query from automatically running
  });
}
