import { Alert } from "@amzn/awsui-components-react";
import { useState } from "react";

interface Props {
  content: string;
  header: string;
}

export default function ErrorAlert(props: Props) {
  const { content, header } = props;
  const [visible, setVisible] = useState(true);
  return (
    <Alert
      onDismiss={() => setVisible(false)}
      visible={visible}
      dismissAriaLabel="Close alert"
      type="error"
      header={header}
    >
      {content}
    </Alert>
  );
}
