import { SpaceBetween } from "@amzn/awsui-components-react";
import { CanonicalBusinessEvent } from "../types";
import CbeEntry from "./CbeEntry";

interface Props {
  cbeData: CanonicalBusinessEvent[];
}

export default function CbeResults(props: Props) {
  const { cbeData } = props;
  return (
    <SpaceBetween size="xl">
      {cbeData.map((cbe) => {
        return <CbeEntry cbe={cbe} key={`${cbe.pk} V${cbe.sk}`} />;
      })}
    </SpaceBetween>
  );
}
