import {
  Box,
  ColumnLayout,
  Container,
  Header,
} from "@amzn/awsui-components-react";
import { CanonicalBusinessEvent, AccountingEventsGroup } from "../types";
import { useContext } from "react";
import { StageContext } from "../contexes/OrderIdContext";

interface Props {
  eventData: AccountingEventsGroup[];
  cbeData: CanonicalBusinessEvent[];
}

const GL_MAPPING: Record<string, string> = {
  318: "TVOD",
  613: "Channels",
  628: "SVOD",
};

export default function Summary(props: Props) {
  const { eventData, cbeData } = props;
  const { accountingEvents } = eventData?.[eventData.length - 1] || undefined;
  const stage = useContext(StageContext);

  const getData = (dataName: string) => {
    return cbeData[0][dataName] as string;
  };

  const getGlMapping = (glProductGroup: number) => {
    if (Object.prototype.hasOwnProperty.call(GL_MAPPING, glProductGroup)) {
      return GL_MAPPING[eventData[0].glProductGroup];
    }
    return glProductGroup;
  };

  const isEventNotEmpty = eventData !== undefined && eventData.length !== 0;

  const isCbeNotEmpty = cbeData !== undefined && cbeData.length !== 0;

  return (
    <Container header={<Header variant="h2">Summary</Header>}>
      <ColumnLayout columns={5} borders="vertical">
        <div>
          <Box variant="awsui-key-label">Stage</Box>
          <div>{stage}</div>
        </div>
        {isEventNotEmpty && "glProductGroup" in eventData[0] ? (
          <div>
            <Box variant="awsui-key-label">GL Product Group</Box>
            <div>{getGlMapping(eventData[0].glProductGroup)}</div>
          </div>
        ) : (
          <div>
            <Box variant="awsui-key-label">GL Product Group</Box>
            <div>-</div>
          </div>
        )}
        {isCbeNotEmpty && "businessCountryCode" in cbeData[0] ? (
          <div>
            <Box variant="awsui-key-label">Country Code</Box>
            <div>{getData("businessCountryCode")}</div>
          </div>
        ) : (
          <div>
            <Box variant="awsui-key-label">Country Code</Box>
            <div>-</div>
          </div>
        )}
        {isEventNotEmpty && accountingEvents !== undefined ? (
          <div>
            <Box variant="awsui-key-label">Order Date</Box>
            <div>
              {new Date(
                Object.values(accountingEvents)[0].orderDate
              ).toUTCString()}
            </div>
          </div>
        ) : (
          <div>
            <Box variant="awsui-key-label">Order Date</Box>
            <div>-</div>
          </div>
        )}
        {isEventNotEmpty && accountingEvents !== undefined ? (
          <div>
            <Box variant="awsui-key-label">Consumption Type</Box>
            <div>{Object.values(accountingEvents)[0].consumptionType}</div>
          </div>
        ) : (
          <div>
            <Box variant="awsui-key-label">Consumption Type</Box>
            <div>-</div>
          </div>
        )}
      </ColumnLayout>
    </Container>
  );
}
