import { Tabs } from "@amzn/awsui-components-react";
import { CanonicalBusinessEvent } from "../types";
import CbeResults from "./CbeResults";

interface Props {
  cbeData: CanonicalBusinessEvent[];
}

const createVersionNumberArray = (cbeData: CanonicalBusinessEvent[]) => { 
  return Array.from(new Set(cbeData.map(cbe => cbe.lockVersion))).sort().reverse(); 
};

export default function CbeVersionSelector(props: Props) {
  const { cbeData } = props;

  return (
    <Tabs
      tabs={createVersionNumberArray(cbeData).map((colName) => ({
        id: `${colName}`,
        label: `Version ${colName}`,
        content: <CbeResults cbeData={cbeData.filter((cbe) => cbe.lockVersion === colName).sort((cbe1, cbe2) => {
            return new Date(cbe1.creationDate).getTime() - new Date(cbe2.creationDate).getTime()
        })} />,
      }))}
    />
  );
}
