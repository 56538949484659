import { useContext, useEffect, useState } from "react";
import {
  Box,
  ColumnLayout,
  Container,
  Select,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Input, { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { useSearchParams } from "react-router-dom";
import useFetchEvent from "../hooks/useFetchEvent";
import useFetchCbe from "../hooks/useFetchCbe";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { GAMMA_STAGE, PROD_STAGE, BETA_STAGE } from "../constants";
import { SearchHistory } from "../types";

interface Props {
  children: React.ReactNode;
  setStage: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({ children, setStage }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState("");
  const [orderIdQuery, setOrderIdQuery] = useState("");
  const orderIdParam = searchParams.get("orderId");
  const stage = useContext(StageContext);
  const stageParam = searchParams.get("stage");
  const [isStageDropdownDisabled, setStageDropdownDisabledStatus] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: PROD_STAGE,
    value: PROD_STAGE,
  });

  const { refetch: refetchEvents } = useFetchEvent(stage, orderId);

  const { refetch: refetchCbes } = useFetchCbe(stage, orderId);

  const ORDER_IDS = "orderIds";
  const ENTER_KEY = "Enter";

  const saveSearchHistory = () => {
    const orderIds: SearchHistory[] = JSON.parse(
      localStorage.getItem(ORDER_IDS) || "[]"
    ) as SearchHistory[];
    const orderIdSearchEntry = {
      date: new Date().toLocaleString(),
      orderId,
      stage,
    };
    orderIds.unshift(orderIdSearchEntry);

    // Only store latest 10 searches and pop the earliest search when its more than 10
    if (orderIds.length > 10) {
      orderIds.pop();
    }
    localStorage.setItem(ORDER_IDS, JSON.stringify(orderIds));
  };

  const handleClick = () => {
    setOrderId(orderIdQuery);
    setStage(selectedOption.value);
    if (orderIdQuery !== "")
      setSearchParams(
        { stage: selectedOption.value, orderId: orderIdQuery },
        { replace: true }
      );
  };

  const handleSelectStageClick = (selectedStage: OptionDefinition) => {
    setSelectedOption({
      label: selectedStage.label!,
      value: selectedStage.value!,
    });
  };

  const handleOnKeyDown = (detail: InputProps.KeyDetail) => {
    if (detail.key === ENTER_KEY) {
      handleClick();
    }
  };

  window.onpopstate = () => {
    setOrderId("");
    setOrderIdQuery("");
  };

  useEffect(() => {
    if (orderIdParam !== null) {
      setOrderId(orderIdParam);
    }
  }, [orderIdParam]);

  useEffect(() => {
    if (stageParam !== null) {
      setStage(stageParam);
    }
  }, [stageParam]);

  useEffect(() => {
    if (orderId !== "" && stage !== "") {
      setStageDropdownDisabledStatus(true);
      setOrderIdQuery(orderId);
      setSelectedOption({
        label: stage,
        value: stage,
      });
      refetchEvents()
        .then(() => setStageDropdownDisabledStatus(false))
        .catch((error) => console.log(error));
      refetchCbes().catch((error) => console.log(error));
      saveSearchHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, stage]);

  return (
    <OrderIdContext.Provider value={orderId}>
      <SpaceBetween size="xl">
        <Container>
          <ColumnLayout columns={1}>
            <Box padding={{ top: "s", bottom: "s" }}>
              <div className="custom-search-bar">
                <div className="custom-search-bar-input">
                  <Input
                    onChange={({ detail }) => setOrderIdQuery(detail.value)}
                    value={orderIdQuery}
                    onKeyDown={({ detail }) => handleOnKeyDown(detail)}
                    type="search"
                    placeholder="Enter Transaction Id (TVOD: Order ID, Subs: AUID)"
                    autoFocus
                  />
                </div>
                <Select
                  className="custom-select"
                  selectedOption={selectedOption}
                  placeholder="Choose an environment"
                  onChange={({ detail }) =>
                    handleSelectStageClick(detail.selectedOption)
                  }
                  options={[
                    { label: PROD_STAGE, value: PROD_STAGE },
                    { label: GAMMA_STAGE, value: GAMMA_STAGE },
                    { label: BETA_STAGE, value: BETA_STAGE },
                  ]}
                  disabled={isStageDropdownDisabled}
                  selectedAriaLabel="Selected"
                />
                <div className="custom-search-bar-items">
                  <Button
                    onClick={handleClick}
                    variant="primary"
                    className="inp"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Box>
          </ColumnLayout>
        </Container>
        {children}
      </SpaceBetween>
    </OrderIdContext.Provider>
  );
}
