import { Header, Toggle } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { applyMode, Mode } from "@amzn/awsui-global-styles";
import { DARK_THEME, LIGHT_THEME } from "../constants";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

interface Props {
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PageHeader(props: Props) {
  const { setDarkMode } = props;
  const [checked, setChecked] = useState(false);
  const [theme, setTheme] = useState(LIGHT_THEME);

  const toggleTheme = (localTheme: string | null) => {
    if (localTheme === DARK_THEME) {
      window.localStorage.setItem("theme", DARK_THEME);
      setTheme(DARK_THEME);
      applyMode(Mode.Dark);
      setDarkMode(true);
      setChecked(true);
    } else {
      window.localStorage.setItem("theme", LIGHT_THEME);
      setTheme(LIGHT_THEME);
      applyMode(Mode.Light);
      setDarkMode(false);
      setChecked(false);
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    toggleTheme(localTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleThemeChange = (isChecked: boolean) => {
    setChecked(isChecked);
    if (theme === LIGHT_THEME) {
      toggleTheme(DARK_THEME);
    } else {
      toggleTheme(LIGHT_THEME);
    }
  };

  return (
    <Header
      variant="h1"
      className="custom-header"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Toggle
            onChange={({ detail }) => handleThemeChange(detail.checked)}
            checked={checked}
          >
            Dark Mode
          </Toggle>
        </SpaceBetween>
      }
      description="Tool to help finding transaction events and CBEs for an order"
    >
      Lusca Search Tool
    </Header>
  );
}
