import { ColumnLayout, Header, Link } from "@amzn/awsui-components-react";
import { useContext } from "react";
import { AccountingEventsGroup } from "../../types";
import { OrderIdContext, StageContext } from "../../contexes/OrderIdContext";
import { BETA_STAGE, PROD_STAGE } from "../../constants";

interface Realms {
  NA: string;
  EU: string;
  FE: string;
}
const realms: Realms = { NA: "na", EU: "eu", FE: "fe" };

// Reference https://code.amazon.com/packages/PVAccountingCDK/blobs/mainline/--/lib/marketplaces.ts
const MARKETPLACE_TO_REALM = {
  A1AM78C64UM0Y8: realms.NA, // MEXICO_PROD
  A3P3J5A7D2ZVXI: realms.NA, // MEXICO_DEVO
  A39IBJ37TRP1C6: realms.FE, // AUSTRALIA_PROD
  A1RNPCQ4K8U27I: realms.FE, // AUSTRALIA_ DEVO
  A1C3SOZRARQ6R3: realms.EU, // POLAND_PROD
  AKY4K4WKH21YQ: realms.EU, // POLAND_DEVO
  AMEN7PMS3EDWL: realms.EU, // BELGIUM_PROD
  A1YFOMBPFBRY22: realms.EU, // BELGIUM_DEVO
  A2NODRKZP88ZB9: realms.EU, // SWEDEN_PROD
  AT614YYYNOC1S: realms.EU, // SWEDEN_DEVO
  A13V1IB3VIYZZH: realms.EU, // FRANCE
  APJ6JRA9NG5V4: realms.EU, // ITALY_PROD
  A3HOBANJMCMD83: realms.EU, // ITALY_DEVO
  A1RKKUPIHCS9HS: realms.EU, // SPAIN_PROD
  AJZF8LZ1EJVJN: realms.EU, // SPAIN_DEVO
  A2EUQ1WTGCTBG2: realms.NA, // CANADA
  A1805IZSGTT6HS: realms.EU, // NETHERLANDS_PROD
  A1M3WC0SJ3A38T: realms.EU, // NETHERLANDS_DEVO
  ART4WZ8MWBX2Y: realms.NA, //  ROW_NA_PROD
  A3U49GLEJ1PS4Y: realms.NA, // ROW_NA_DEVO
  A3K6Y4MI8GDYMT: realms.EU, // ROE_EU_PROD
  A1MJEQWJOUEPEQ: realms.EU, // ROE_EU_DEVO
  AZXD3QD5B39HD: realms.NA, // BRAZIL_DEVO
  A2Q3Y263D00KWC: realms.NA, // BRAZIL_PROD
  A2MFUE2XK8ZSSY: realms.EU, //ROW_EU_PROD
  A1D7Z662KHSYZY: realms.EU, // ROW_EU_DEVO
  A15PK738MTQHSO: realms.FE, // ROW_FE_PROD
  A9QSUUOYRWVOG: realms.FE, // ROW_FE_DEVO
  ATVPDKIKX0DER: realms.NA, // US
  A1F83G8C2ARO7P: realms.EU, // UK
  A1PA6795UKMFR9: realms.EU, // GERMANY
  A1VC38T7YXB528: realms.FE, // JAPAN
  A21TJRUUN4KGV : realms.EU // India
};

const createFlashUILink = (
  marketplaceId: string,
  orderId: string,
  selectedStage: string
) => {
  let realm = MARKETPLACE_TO_REALM[marketplaceId];
  let stage =
    selectedStage == PROD_STAGE ? "" : `-${selectedStage.toLowerCase()}`;
  let alias = selectedStage == BETA_STAGE ? "integ" : "aka";
  return `https://flashui${stage}-${realm}.${alias}.amazon.com/search/transaction?transactionId=${orderId}`;
};

const createDigiConLink = (marketplaceId: string, orderId: string) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  if (realm !== realms.NA) {
    const realmSuffix = `${realm.toUpperCase()}Amazon`
    return `https://digicon-${realm}.amazon.com/order-dumper?orderID=${orderId}&realm=${realmSuffix}`;
  }
  return `https://digicon.amazon.com/order-dumper?orderID=${orderId}`;
};

const createRaceLink = (
  marketplaceId: string,
  orderId: string,
  selectedStage: string
) => {
  const namespace = selectedStage == PROD_STAGE ? "prod" : "uat";
  if (selectedStage == BETA_STAGE) {
    return `https://race-website.integ.amazon.com:13743/deferral_search/index?deferral-id=${orderId}&namespace=test`;
  }
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  return `https://race-website-${realm}.aka.amazon.com/deferral_search/index?deferral-id=${orderId}&namespace=${namespace}`;
};

interface Props {
  eventData: AccountingEventsGroup;
}

export default function ExternalLinks(props: Props) {
  const { eventData } = props;
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);
  const getMarketplaceId = () => {
    return Object.values(eventData.accountingEvents)[0].marketplaceId as string;
  };

  const getDigitalOrderId = () => {
    return Object.values(eventData.accountingEvents)[0].orderId as string;
  };

  return (
    <div>
      {typeof eventData !== "undefined" && (
        <ColumnLayout columns={2}>
          <Header>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href={createFlashUILink(getMarketplaceId(), orderId, stage)}
              data-testid="FlashLink"
            >
              Flash UI Transaction Search
            </Link>
          </Header>
          <Header>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href={createRaceLink(getMarketplaceId(), orderId, stage)}
              data-testid="RaceLink"
            >
              Race Deferrals
            </Link>
          </Header>
          {stage !== BETA_STAGE && (
            <Header>
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={createDigiConLink(getMarketplaceId(), getDigitalOrderId())}
                data-testid="DigiconLink"
              >
                DigiCon Order Dumper
              </Link>
            </Header>
          )}
        </ColumnLayout>
      )}
    </div>
  );
}
