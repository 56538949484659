const BASE_16_CUSTOM_DARK_THEME = {
  base00: "#0f1b2a",
  base01: "white",
  base02: "rgb(88, 110, 117)",
  base03: "white",
  base04: "rgb(88, 110, 117)",
  base05: "white",
  base06: "white",
  base07: "rgb(233, 235, 237)",
  base08: "rgb(38, 139, 210)",
  base09: "rgb(203, 75, 22)",
  base0A: "white",
  base0B: "rgb(133, 153, 0)",
  base0C: "rgb(133, 153, 0)",
  base0D: "rgb(88, 110, 117)",
  base0E: "white",
  base0F: "rgb(38, 139, 210)",
};

const LIGHT_THEME = "light";
const DARK_THEME = "dark";

const PROD_STAGE = "Prod";
const GAMMA_STAGE = "Gamma";
const BETA_STAGE = "Beta";

export {
  BASE_16_CUSTOM_DARK_THEME,
  LIGHT_THEME,
  DARK_THEME,
  PROD_STAGE,
  GAMMA_STAGE,
  BETA_STAGE,
};
