import {
  Box,
  Container,
  ExpandableSection,
  Popover,
  StatusIndicator,
  TextContent
} from "@amzn/awsui-components-react";

import ReactJson from "react-json-view";
import { useContext } from "react";
import { CanonicalBusinessEvent } from "../types";
import DarkModeContext from "../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../constants";

interface Props {
  cbe: CanonicalBusinessEvent;
}

export default function CbeEntry(props: Props) {
  const { cbe } = props;
  const darkMode = useContext(DarkModeContext);
  return (
    <Container>
      {PublishStatusComponent(cbe)}
      <ExpandableSection
        variant="default"
        header={
          <>
            <Box variant="h4">
              {cbe.pk} V{cbe.sk + "  "}
              <br/>
              <TextContent>
                <small>Created on: {new Date(cbe.creationDate).toISOString().replace("T", " ")}</small>
              </TextContent>
            </Box>
          </>
        }
        key={`${cbe.pk} V${cbe.sk}`}
      >
        <ReactJson
          src={cbeWithoutPublishStatus(cbe)}
          displayDataTypes={false}
          sortKeys
          theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
        />
      </ExpandableSection>
    </Container>
  );
}

// Since publish status is not an actual field from a CBE,
// we can delete it from the JSON as we've already rendered it in the Popover
function cbeWithoutPublishStatus(cbe: CanonicalBusinessEvent) {
  const cbeCopy = { ...cbe };
  delete cbeCopy.publishStatus;
  return cbeCopy;
}

function PublishStatusComponent(cbe: CanonicalBusinessEvent) {
  if (cbe.idempotenceId.includes("NONE")) {
    return <StatusIndicator type="info">NoOp</StatusIndicator>;
  } else if (cbe.publishStatus === null || cbe.publishStatus === undefined) {
    return <StatusIndicator type="pending">Status Unknown/Pending Publish</StatusIndicator>;
  } else if (cbe.publishStatus?.successFlag === true) {
    return <StatusIndicator type="success">Publish Success</StatusIndicator>;
  } else {
    return (
      <Popover
        fixedWidth={false}
        size="large"
        triggerType="text"
        header={cbe.publishStatus?.message}
        content={cbe.publishStatus?.stackTrace || "No error details"}
      >
        <StatusIndicator type={"error"}>{"Error Publishing"}</StatusIndicator>
      </Popover>
    );
  }
}
